import { template as template_9aebc35d8be2405b9f66c633c4f07a9e } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_9aebc35d8be2405b9f66c633c4f07a9e(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
