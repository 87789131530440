import { template as template_b6f6c7f5d7d844388987e278dd5f4a51 } from "@ember/template-compiler";
const FKLabel = template_b6f6c7f5d7d844388987e278dd5f4a51(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
