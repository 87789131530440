import { template as template_8bbcc2a597954ad5b219da7110e75aef } from "@ember/template-compiler";
import Component from "@glimmer/component";
import DButton from "discourse/components/d-button";
export default class PostMenuAdminButton extends Component {
    static shouldRender(args) {
        return (args.post.canManage || args.post.can_wiki || args.post.canEditStaffNotes);
    }
    static{
        template_8bbcc2a597954ad5b219da7110e75aef(`
    <DButton
      class="post-action-menu__admin show-post-admin-menu"
      ...attributes
      @action={{@buttonActions.openAdminMenu}}
      @forwardEvent={{true}}
      @icon="wrench"
      @label={{if @showLabel "post.controls.admin_action"}}
      @title="post.controls.admin"
    />
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
