import { template as template_f6cabf82f8a5446d9a8a6b6fd8a4af05 } from "@ember/template-compiler";
import { hash } from "@ember/helper";
const DropdownItem = template_f6cabf82f8a5446d9a8a6b6fd8a4af05(`
  <li class="dropdown-menu__item" ...attributes>{{yield}}</li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const DropdownDivider = template_f6cabf82f8a5446d9a8a6b6fd8a4af05(`
  <li ...attributes><hr class="dropdown-menu__divider" /></li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const DropdownMenu = template_f6cabf82f8a5446d9a8a6b6fd8a4af05(`
  <ul class="dropdown-menu" ...attributes>
    {{yield (hash item=DropdownItem divider=DropdownDivider)}}
  </ul>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default DropdownMenu;
