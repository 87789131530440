import { template as template_efd3860fcf894e27a0168eec133832b0 } from "@ember/template-compiler";
const WelcomeHeader = template_efd3860fcf894e27a0168eec133832b0(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
