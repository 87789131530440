import { template as template_b50f7243364449e89098f154ab4afa12 } from "@ember/template-compiler";
const SidebarSectionMessage = template_b50f7243364449e89098f154ab4afa12(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
